import { createTheme } from "@mui/material/styles";

const PRIMARY_MAIN = "#1D2739";
const RED = "#FE4040";
const WHITE = "#FBFBFD";

export default createTheme({
  palette: {
    primary: {
      main: PRIMARY_MAIN,
    },
    secondary: {
      main: RED,
    },
    background: {
      default: WHITE,
    },
  },
  typography: {
    fontSize: 14,
    h1: {
      fontSize: "3.5rem",
      color: PRIMARY_MAIN,
    },
    h2: {
      color: PRIMARY_MAIN,
    },
    h3: {
      color: PRIMARY_MAIN,
    },
    h4: {
      color: PRIMARY_MAIN,
    },
    body1: {
      color: "#333333",
    },
  },
});
