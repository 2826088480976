import { ReactElement } from "react";
import type { AppProps } from "next/app";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "@/interface/theme";
import { ThemeProvider } from "@mui/material";

export default function MyApp({
  Component,
  pageProps,
}: AppProps): ReactElement {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  );
}
